import React, { FC } from "react";
import Layout from "../components/layout";
import { H2, H5, Paragraph } from "../components/ui/typography";
import { graphql, useStaticQuery } from "gatsby";
import { Query } from "../generated/graphql";
import Img, { FluidObject } from "gatsby-image";
import { Header } from "../components/ui/semanticElements/header";
import { Main } from "../components/ui/semanticElements/main";
import { Figcaption, Figure } from "../components/ui/figure";

const WorkPage: FC = () => {
  const data = useStaticQuery<Query>(graphql`
    query {
      allContentfulPortfolio {
        nodes {
          seoPageTitle
          seoPageDescription
          title
          intro
          projects {
            client
            year
            services
            description
            banner {
              title
              description
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `);
  const { seoPageTitle, seoPageDescription, title, intro, projects } = data.allContentfulPortfolio.nodes[0];

  return (
    <Layout pageTitle={seoPageTitle as string} pageDescription={seoPageDescription as string}>
      <Header>
        <H2 as="h1">{title}</H2>
        <Paragraph as="h2">{intro}</Paragraph>
      </Header>
      <Main>
        {projects?.map(p => {
          if (p) {
            return (
              <Figure>
                <Img
                  fluid={p.banner?.fluid as FluidObject}
                  title={p.banner?.title as string}
                  alt={p.banner?.description as string}
                />
                <Figcaption>
                  <H5>{p.client}</H5>
                  <Paragraph>{p.year}</Paragraph>
                  <Paragraph>{p.services?.join(", ")}</Paragraph>
                  <Paragraph>{p.description}</Paragraph>
                </Figcaption>
              </Figure>
            );
          }
        })}
      </Main>
    </Layout>
  );
};

export default WorkPage;
